@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$dark: #1c170d;
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

// spinner
.spinner {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    display: flex;
}

// HomePage
@media (min-width: 600px) {
    div.homePage{
        filter: opacity(.9);
        position: absolute;
        right: 0px;
        width: 100vw;
        background-color: $dark;
        height: 100vh;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 10;
        .homePageCarousel{
            height: 40vh;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            display: flex;
            object-fit: cover;
            .carousel-item {
                object-fit: cover;
                h1 {
                    font-size: 1rem;
                }
            }
        }
        .homePageCard {
            height: 40vh;
            overflow: hidden;
            border-radius: 0;
            object-fit: contain;
            .storeDetails {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                text-align: center;
            }
        }
        .homeCategory {
            display: flex;
            flex-direction: row;
            height: 20vh;
            overflow: hidden;
            .home-entry{
                overflow: hidden;
                object-fit: cover;
                border-radius: 0;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:hover {
                    filter: opacity(.7);
                    
                }
                .storeDetails {
                    font-size: 3rem;
                    text-shadow: .2rem .2rem .5rem #1c170d;
                    color: whitesmoke;
                    text-align: center;
                    &:hover {
                        color: black;
                        text-shadow: .2rem .2rem .5rem whitesmoke;
                    }
                }
                .home-entry-img {
                    filter: grayscale(1);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}


@media (max-width: 599px){
    div.homePage{
        filter: opacity(.9);
        position: absolute;
        right: 0px;
        width: 100vw;
        background-color: $dark;
        height: 100vh;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 10;
        .homePageCarousel{
            height: 40vh;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            display: flex;
            object-fit: contain;
            #carousel-img {
                height: 40vh;
                width: 100%;
                object-fit: cover;
            }
            .carousel-item {
                object-fit: cover;
                h1 {
                    font-size: 2rem;
                }
            }
        }
        .homePageCard {
            height: 100vh;
            overflow: hidden;
            object-fit: fill;
            background-color: pink;
            #image{
                object-fit: cover;
                width: 100%;
                height: 100vh;
                opacity: .6;
                filter: blur(1);
            }
            .storeDetails {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                text-align: center;
            }
        }
        .homeCategory {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            height: 60vh;
            overflow: hidden;
            .home-entry{
                overflow: hidden;
                object-fit: cover;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:hover {
                    filter: opacity(.7);
                }
                .storeDetails {
                    font-size: 2rem;
                    text-shadow: .2rem .2rem .5rem #1c170d;
                    color: whitesmoke;
                    text-align: center;
                    &:hover {
                        color: black;
                        text-shadow: .2rem .2rem .5rem whitesmoke;
                    }
                }
                .home-entry-img {
                    filter: grayscale(1);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}



// productview
.product-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    height: 90vh;
    .img-details {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .product-details {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem 0;
        margin: 1rem;
        padding: 2rem;
        font-size: 1rem;
    }
}
@media (max-width: 599px) {
    .product-view {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid: 2rem 0;
        overflow: hidden;
        height: auto;
        .img-details {
            height: 50vh;
            width: 100%;
            margin: 1rem 0;
        }
        .product-details {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 1rem 0;
            margin: .5rem;
            padding: .5rem;
            font-size: 1rem;
            text-align: center;

            .product-qty{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

// error page
.errorPage {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// navbar
.cartLogo {
    height: 1.5rem;
    width: 1.5rem;
    filter: invert(1)
}
.wrlogo {
    position: inherit;
    height: 3rem; 
    width: 3em;
    top: .3rem;
    z-index: 10;
    filter: invert(1)
}
.navCheckoutBtn {
    width: 100%;
}

// Filter Section
.filterSection {
    width: 100%;
    left: 0px;
    display: inline-flex;
    justify-content: space-evenly;
    background-color: $dark;
    margin: 1rem 0;
    padding: .2rem;
    border-radius: 10px;
}
@media (max-width: 599px) {
    .filterSection {
        width: 100%;
        left: 0px;
        display: grid;
        grid-gap: .5rem;
        grid-template-columns: auto;
        justify-content: space-evenly;
        background-color: $dark;
        margin: 1rem 0;
        padding: .2rem;
        border-radius: 10px;
    }
}

.product-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    justify-content: center;
    .card {
        height: 40vh;
        margin: 1rem;
        text-align: center;
        text-decoration: none;
        padding: auto;
        overflow: hidden;
        &:hover{
            box-shadow: .2rem .2rem .5rem grey;
        }
        Img {
            width: 100%;
        }
        .card-btn{
            width: 100%;
            height: 100%;
            text-decoration: none;
            border-radius: 0;
            background-color: white;
            color: $dark;
            border: none;
        }
    }
}
.card-body {
    text-decoration: none;
}
// Admin Tools
#admin-label {
    background-color: $dark;
    color: white;
}
.prod-list-main {
    background-color: lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem;
    .admin-product-btn {
        width: 10em;
        font-size: .8rem;
        margin: 0 .5rem;
    }
    .btn{
        width: 10em;
        height: 2rem;
        font-size: .8rem;
    }
    .prod-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        .prod-list-details {
            width: 20%;
            font-size: .8rem;
            justify-content: space-evenly;
            text-align: center
        }
        .admin-badge {
            width: 6rem;
        }
    }
}
// Admin Orders
.order-container {
    border: 2px solid black;
    margin: 2rem 0;
    padding: 1rem;
    .empty-order{
        font-size: 1.5rem;
        text-align: center;
    }
    .order-title-container{
        display: flex;
        background-color: black;
        color: white;
        margin: .2rem 0 .2rem 1rem;
        padding: 0;
        font-size: .8rem;
        text-align: center;
        justify-content: space-between;
        .order-title-id{
            width: 15%;
            list-style: none;
        }
        .order-title-date{
            width: 10%;
            list-style: none;
        }
        .order-title-status{
            width: 10%;
            list-style: none;
        }
        .order-title-items{
            list-style: none;
            width: 45%;
        }
        .order-title-total{
            width: 20%;
            list-style: none;
            text-align: end;
            padding: 0 1rem 0 0
        }
    }
    .list-group-item{
        background-color: lightgray;
        margin: .2rem 0 .2rem 1rem;
        padding: 0;
        text-align: center;
        .order-detail-id{
            width: 15%;
            list-style: none;
        }
        .order-detail-date{
            width: 10%;
            list-style: none;
        }
        .order-detail-status{
            width: 10%;
            list-style: none;
        }
        .order-detail-items{
            list-style: none;
            width: 45%;
        }
        .order-detail-total{
            width: 20%;
            list-style: none;
            text-align: end;
            padding: 0 1rem 0 0
        }
    }
    .order-name-container{
        width: 100&;
        display: flex;
        justify-content: space-between;
        font-size: .8rem;
        padding: 0;
    }
}
// Cart
.cart-container {
    border: 2px solid black;
    margin: 2rem 0;
    padding: 1rem;
    .cart-title-container{
        display: flex;
        background-color: black;
        color: white;
        margin: .2rem 0 .2rem 1rem;
        .cart-title-name {
            width: 50%;
            list-style: none;
        }
        .cart-title-qty{
            width: 10%;
            list-style: none;
            text-align: center;
        }
        .cart-title-price{
            width: 20%;
            list-style: none;
            text-align: center;
        }
        .cart-title-total{
            width: 20%;
            list-style: none;
            text-align: end;
            padding: 0 1rem 0 0
        }
    }
    .empty-cart {
        font-size: 1.5rem;
        text-align: center;
    }
    .list-group-item {
        background-color: lightgray;
        margin: .2rem 0 .2rem 1rem;
        .cart-detail-name {
            width: 50%;
            list-style: none;
        }
        .cart-detail-qty{
            width: 10%;
            list-style: none;
            text-align: center;
        }
        .cart-detail-price{
            width: 20%;
            list-style: none;
            text-align: center;
        }
        .cart-detail-total{
            width: 20%;
            list-style: none;
            text-align: end;
        }
    }
    .cart-name-container {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
    }
    .cart-btns-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .cart-qty {
            width: 2rem;
            font-size: 1rem;
            text-align: center;
            padding: .5rem;
        }
        .qty-btns {
            display: flex;
            margin: .5rem 0 0 0;
        }
        .rm-btn {
            height: 1rem;
            width: 1rem;
            padding: 0;
            font-size: .5rem;
            bottom: -2px;
            border-radius: 25px;
        }
    }
}
.details-qty-btns {
    display: flex;
    margin: 0 0 1rem 0;
    .cart-qty {
        width: 2rem;
        font-size: 1rem;
        text-align: center;
        padding: .5rem;
    }
}

.total-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    margin: 1rem 0 1rem 0;
    .cart-total {
        margin: 0 1rem 0 0;
    }
}
.btn-container {
    display: flex;
    justify-content: space-between
}

// Edit product
.edit-title {
    display: flex;
    margin: 2rem 0 0 0;
    justify-content: center;
}
.admin-edit-product {
    margin: 2rem;
    .admin-edit-input {
        margin: 1rem 0 0 0;
    }
    .admin-edit-btn {
        width: 100%;
        margin: 1rem 0;
    }
}
.isactive-btn {
    display: flex;
    margin: 0;
}

// Account page
.account-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .account-entry {
        display: flex;
        margin: .2rem;
        width: 100%;
        .account-title {
            width: 50%;
            height: 2rem;
            text-align: end;
            padding: .2rem 1rem .2rem 0;
        }
        .account-details{
            height: 2rem;
            padding: .2rem 0 .2rem 1rem;
            width: 50%;
        }

    }
    .account-edit-btn {
        margin: 1rem 0 0 0;
        width: 8em;
        border-radius: 25px
    }
}

.footer-container {
    display: block;
    margin-top: 2rem;
    opacity: .7;
    position: fixed;
    background-color: $dark;
    height: 10vh;
    bottom: -10%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 0.2s ease-in-out;
    .footer-text {
        font-size: 1rem;
        color: whitesmoke
    }
}

@import "~bootstrap/scss/bootstrap.scss";